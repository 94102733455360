import React, { Component } from 'react';
import Button from '../ui/button';
import * as style from './headerIntro.module.scss';

class HeaderIntro extends Component {
	constructor(props) {
		super(props);
		this.state = {
			width: 0, 
		};
	}
	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', () => {this.updateWindowDimensions()});
	}
	componentWillUnmount() {
		window.removeEventListener('resize', () => {this.updateWindowDimensions()});
	}
	updateWindowDimensions() {
		this.setState({ width: window.innerWidth });
	}

	render() {
		const width = (this.state) ? this.state.width: 0;
		const backgroundStyle = {
			backgroundImage: '',
			backgroundSize: 'cover',
		};
		const mobileBackgroundStyle = {
			backgroundImage: ''
		};

		if(this.props.backgroundImage && this.props.backgroundImage.image) {
			if(false) { // if(width <= 1200) {
				mobileBackgroundStyle.backgroundImage = `url(${this.props.backgroundImage.image.sizes.hero_lg})`;
				backgroundStyle.backgroundImage = '';
			} else {
				backgroundStyle.backgroundImage = `url(${this.props.backgroundImage.image.sizes.hero_lg})`;
				mobileBackgroundStyle.backgroundImage = '';
			}
		}

		return (
		<div className={`${style.videoFeatureIntro}`} style={ backgroundStyle }>
			{/* <div className={`${style.videoFeatureIntroBackground}`} style={ mobileBackgroundStyle } /> */}
			<div className={style.videoFeatureWrapper}>
				<div className={style.videoFeatureContainer}>

					{/* <div className={style.videoFeatureIntroText}> */}

					<div
                        className={`${style.videoFeatureIntroText} fadeInBlur animated delay-1s ${
                            this.props.shouldReveal ? 'fadeInBlur animated delay-1s' : ''
                        }`}
                    >
						<div className={style.videoFeatureIntroTextContent}>

							<div className={style.videoFeatureIntroTextHeader}>
								{this.props.heading}
							</div>

							<div className={style.videoFeatureIntroTextDescription} dangerouslySetInnerHTML={{__html: this.props.description}} />

							<div className={style.conferenceMeta}>
								
								<span className={style.metaDate}>
									{this.props.date_text}
								</span>

								{/*
								{this.props.date}
								{this.props.end_date &&
									<span> - {this.props.date}</span>
								}
								*/}
								{/*
								<span className={style.pipe}>&nbsp;|&nbsp;</span>
								{this.props.locationType}
								*/}

								{/*
								<span className={style.pipe}>&nbsp;|&nbsp;</span>
								*/}

								<span className={style.metaLocation}>
									{this.props.locationText}
								</span>
							</div>

							<div className={style.conferenceLinkWrap}>
								{/*
								<a
									href={this.props.registrationLinkUrl}
									className={``}
									target="_blank"
									rel="noopener"
								>
									{this.props.registrationLinkText}
								</a>
								*/}
								
								{this.props.registrationLinkUrl && this.props.registrationLinkText &&
									<Button
										color="orange"
										to={this.props.registrationLinkUrl}
										text={this.props.registrationLinkText}
										target="external"
									/>
								}

							</div>
						</div>
						
						<div className={style.spacer} />
					</div>

					{/* <div className={style.videoFeatureVideoWrapper}> */}

					<div
                        className={`${style.videoFeatureVideoWrapper} fadeInBlur animated delay-1s ${
                            this.props.shouldReveal ? 'fadeInBlur animated delay-1s' : ''
                        }`}
                    >

						{/*
						<VideoEmbed
							imgSrc={this.props.video.image ? this.props.video.image.sizes.original : ''}
							videoUrl={this.props.video.url}
							videoId={this.props.video.id}
							heading=""
							isFeatured
							small
							canPlayUploadedVideos={true}
							videoStyle={style.videoFeatureVideo}
							showVideo={true}
						/>
						*/}
					</div>					
				</div>
			</div>
		</div>
		);
	}
};

export default HeaderIntro;
