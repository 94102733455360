import React, { Suspense, useContext } from 'react';
import Reveal from '../ui/reveal';
import translations from '../../utils/translations';
import Loading from '../global/loading';
import RoundedButton from '../buttons/roundedButton';
import { navigate } from 'gatsby-link';

const AnnotatedImage = React.lazy(() => import('./annotatedImage'));
const ArticleCarousel = React.lazy(() => import('./articleCarousel'));
const ArticleList = React.lazy(() => import('./articleList'));
const ArticleText = React.lazy(() => import('./articleText'));
const ArticleVideoCallout = React.lazy(() => import('./articleVideoCallout'));
const BottomCallouts = React.lazy(() => import('./bottomCallouts'));
const FaqSection = React.lazy(() => import('./faqSection'));
const Hotspots = React.lazy(() => import('./hotspots'));
const ImageCaption = React.lazy(() => import('./imageCaption'));
const ImageCaptionAlt = React.lazy(() => import('./imageCaptionAlt'));
const PopularStories = React.lazy(() => import('./popularStories'));
const Pullquote = React.lazy(() => import('./pullquote'));
const ResourcesCallout = React.lazy(() => import('./resourcesCallout'));
const SimpleCallout = React.lazy(() => import('./simpleCallout'));
const CreditCallout = React.lazy(() => import('../basic/creditCallout'));
const InlineMailChimpForm = React.lazy(() => import('../basic/inlineMailChimpForm'));
const VideoEmbed = React.lazy(() => import('../basic/videoEmbed'));
const SectionHeading = React.lazy(() => import('../global/sectionHeading'));
const Promo = React.lazy(() => import('../magazine/promo'));
const TextCarousel = React.lazy(() => import('../timeline/textCarousel'));
const isSSR = typeof window === "undefined";

const articleMapDataToComponents = (paragraphs, popularStories, config, isFAQ) => {
  const paragraphData = (
    paragraphs &&
    paragraphs.map((p, i) => {
      switch (p.type) {
        case 'intro_text':
          return (
            <ArticleText key={`articleItem${p.type}${i}`} isIntro isArticle>
              <p className="intro">{p.text}</p>
            </ArticleText>
          );
        case 'section_heading':
          return (
            <SectionHeading
              color={config.theme === 'dark' ? 'typeColorDark1' : 'typeColorBlack'}
              side={config.theme === 'dark' ? 'left' : 'right'}
              key={`articleItem${p.type}${i}`}
              heading={p.section_heading}
              prefix={p.section_prefix}
              isFAQ={isFAQ}
            />
          );
        case 'video_background_callout':
          return (
            <Reveal key={`articleItem${p.type}${i}`}>
              <ArticleVideoCallout {...p} />
            </Reveal>
          );
        case 'textarea':
          return (
            <ArticleText key={`articleItem${p.type}${i}`} className={config.textClass || ''} isArticle twitterEmbed={p.text.indexOf('twitter-tweet') !== -1}>
              <div dangerouslySetInnerHTML={{ __html: p.text }} />
            </ArticleText>
          );
        case 'pull_quote':
          return (
            <Reveal key={`articleItem${p.type}${i}`}>
              <Pullquote
                quoteText={p.text}
                captionText={p.byline_description}
              />
            </Reveal>
          );
        case 'single_image':
          return (
            <Reveal key={`articleItem${p.type}${i}`}>
              <ImageCaption
                containerWidth={config.theme === 'dark' ? 'widthNormal' : 'widthFull'}
                src={p.image.sizes.fixed_width_sm}
                imgDimensions={p.image ? p.image.dimensions.fixed_width_sm : ''}
                original={p.image.sizes.original}
                altText={p.image.alt_text}
                captionText={p.image && p.image.caption}
                creditText={p.image && p.image.credit}
              />
            </Reveal>
          );
        /*
        case 'single_image_original':
          return (
            <Reveal key={`articleItem${p.type}${i}`}>
              <img
                src={p.image_url}
                alt={p.alt_test}
                title={p.title}
              />
            </Reveal>
          );
        */
        case 'single_image_original':
          return (
            <Reveal key={`articleItem${p.type}${i}`}>
              <ImageCaptionAlt
                containerWidth={'widthFull'}
                src={p.image_url}
                original={p.image_url}
                altText={p.alt_text}
                captionText={p.caption}
                creditText={p.credit}
                imgFullWidth={p.full_width}
              />
            </Reveal>
          );
        case 'full_width_image':
          return (
            <Reveal key={`articleItem${p.type}${i}`}>
              <ImageCaption
                containerWidth="widthFull"
                src={p.image.sizes.wide_lg}
                imgDimensions={p.image ? p.image.dimensions.wide_lg : ''}
                altText={p.image.alt_text}
                captionHead={p.image.title}
                captionText={p.image && p.image.caption}
                creditText={p.image && p.image.credit}
              />
            </Reveal>
          );
        case 'image_carousel':
          return (
            <Reveal key={`articleItem${p.type}${i}`}>
              <ArticleCarousel {...p} />
            </Reveal>
          );
        case 'video_callout':
          return (
            <div className="text-block" key={`articleItem${p.type}${i}`}>
              <Reveal>
                <VideoEmbed
                  heading={p.callout_heading}
                  date={p.timeline_info ? p.timeline_inf.year : null}
                  imgSrc={p.image ? p.image.sizes.landscape_md : ''}
                  videoUrl={p.video.url}
                  videoId={p.video.id}
                  videoBackground={
                    p.video_background ? p.video_background.url : ''
                  }
                  text={p.text ? p.text : ''}
                  startTimeSeconds={p.start_time_seconds}
                  startTimeMinutes={p.start_time_minutes}
                  small
                  isArticle
                />
              </Reveal>
            </div>
          );
        case 'resources_callout':
          return (
            <div className="resources-item" key={`articleItem${p.type}${i}`}>
              <Reveal key={`articleItem${p.type}${i}`}>
                <ResourcesCallout
                  key={`articleItem${p.type}${i}`}
                  calloutHeading={translations.t('Resources')}
                  text={p.text}
                  cta_link={p.cta_link}
                  externalLinks={p.external_links.map(item => ({
                    title: item.title,
                    url: item.url,
                  }))}
                />
              </Reveal>
            </div>
          );
        case 'simple_callout_with_link':
          return (
            <div className="simple-callout-item" key={`articleItem${p.type}${i}`}>
              <Reveal key={`articleItem${p.type}${i}`}>
                <SimpleCallout
                  key={`articleItem${p.type}${i}`}
                  text={p.text}
                  cta_link={p.cta_link}
                />
              </Reveal>
            </div>
          );
        case 'bottom_callouts':
          return (
            <div
              className="bottomCallouts-item"
              key={`articleItem${p.type}${i}`}
            >
              <Reveal>
                <BottomCallouts
                  itemCallouts={p.items.map(item => ({
                    calloutHeading: item.title,
                    calloutSubtitle: item.callout_subtitle,
                    calloutPath: item.path,
                    cta_link: item.cta_link ? item.cta_link : '',
                    src: item.image
                      ? p.items.length > 1
                        ? item.image.sizes.square_md
                        : item.image.sizes.wide_md
                      : '',
                    altText: item.image ? item.image.alt_text : '',
                  }))}
                />
              </Reveal>
            </div>
          );
        case 'interactive_hotspots':
          return (
            <Reveal key={`articleItem${p.type}${i}`}>
              <Hotspots
                src={p.image.sizes.original}
                imgDimensions={p.image ? p.image.dimensions.original : ''}
                altText={p.image.alt_text}
                heading={p.callout_heading}
                hotspots={p.hotspots}
                caption={p.image.caption}
                size={p.hotspot_style}
              />
            </Reveal>
          );
        case 'annotated_image_callout':
          return (
            <Reveal key={`articleItem${p.type}${i}`}>
              <AnnotatedImage
                header={p.callout_heading}
                src={p.image.sizes.square_md}
                altText={p.image.alt_text}
                captionHead={p.image.title}
                captionText={p.image.caption}
                textBlocks={
                  p.items &&
                  p.items.map(item => ({
                    header: item.callout_heading,
                    subheader: item.callout_subtitle,
                    text: item.text,
                  }))
                }
              />
            </Reveal>
          );
        case 'text_carousel':
          return (
            <TextCarousel
              header={p.callout_heading}
              isArticle
              key={`articleItem${p.type}${i}`}
              items={p.items.map(item => ({
                heading: item.callout_heading,
                src: item.image ? item.image.sizes.square_sm : '',
                original: item.image ? item.image.sizes.original : '',
                altText: item.image ? item.image.alt_text : '',
                text: item.text,
              }))}
            />
          );
        case 'credit_callout':
          return (
            <div key={`articleItem${p.type}${i}`}>
              {p.items.map((item, j) => (
                <Reveal key={`articleItem${p.type}${i}${j}`}>
                  <CreditCallout
                    src={item.image.sizes.original}
                    alt={item.image.alt_text}
                    text={item.logo_text}
                    link={item.cta_link.url}
                  />
                </Reveal>
              ))}
            </div>
          );
        case 'responsive_youtube_video':
          return (
            <div key={`articleItem${p.type}${i}`}>
              <div className="video-container">
                <iframe
                  src={`https://www.youtube.com/embed/${p.video_id}?modestbranding=1&rel=0&iv_load_policy=3&origin=https://outrider.org`}
                  allowfullscreen
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture">
                </iframe>
              </div>
              {p.video_caption
                && <div className="video-caption">
                  <p>{ p.video_caption }</p>  
                </div>
              }
            </div>
          );
        case 'inline_mailchimp_form':
          return (
            <InlineMailChimpForm key={`articleItem${p.type}${i}`} />
          );
        case 'article_list':
          return (<ArticleList
            key={`articleItem${p.type}${i}`}
            label={p.label}
            articles={p.items}
          />);
        case 'grid_text_callout':
          return (<div className="page-block text-block" key={`articleItem${p.type}${i}`}><Promo promo={p} size="small" /></div>);
        case 'popular_stories':
          return (<PopularStories key={`articleItem${p.type}${i}`} articles={popularStories.articles} />)
        case 'simple_button':
          return (
            <RoundedButton
              centered={true}
              text={p.title}
              onClick={() => {
                if (config.simpleButtonOpenBlank && config.simpleButtonOpenBlank === true) {
                  window.open(p.cta_link.url, '_blank');
                } else {
                  navigate(p.cta_link.url)
                }
              }} type="button"
              url={p.url}
            />
          )
        default:
          return null;
      }
    })
  );

  return paragraphData.map((paragraph, index) => {
    return (
      <>
        {!isSSR && (
          <Suspense key={`suspense${index}`} fallback={<Loading />}>
            {paragraph}
          </Suspense>
        )}
      </>
    );
  });
};

export const articleFaqMap = (paragraphs, width, isFAQ) => {
  const faqSections = paragraphs.reduce((curr, next) => {
    if (next.type === 'bottom_callouts' || next.type === 'credit_callout') {
      return curr;
    }
    if (next.type === 'section_heading' || next.type === 'resources_callout' || next.type === 'inline_mailchimp_form') {
      curr.push([]);
    }

    const pushDest = curr[curr.length - 1];
    if (typeof pushDest !== 'undefined') {
      pushDest.push(next);
    }
    return curr;
  }, []);

  const sections = faqSections.map((faqSect, i) => {
    if (faqSect.length > 0 && (faqSect[0].type === 'resources_callout' || faqSect[0].type === 'inline_mailchimp_form')) {
      return (<div key={`faqSect${i}`}>{articleMapDataToComponents(faqSect, null, width, isFAQ)}</div>);
    } else {
      return (
        <FaqSection key={`faqSect${i}`}>
          {articleMapDataToComponents(faqSect, null, width, isFAQ)}
        </FaqSection>
      );
    }
  });
  const lastP = paragraphs[paragraphs.length - 1];
  if (lastP.type === 'bottom_callouts' || lastP.type === 'credit_callout') {
    sections.push(articleMapDataToComponents([lastP]));
  }

  return sections.map((paragraph, index) => {
    return (
      <>
        {!isSSR && (
          <Suspense key={`suspense${index}`} fallback={<Loading />}>
            {paragraph}
          </Suspense>
        )}
      </>
    );
  });
};

export default articleMapDataToComponents;
